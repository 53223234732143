<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
export default {
  components: {
    Navbar,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  color: #000000;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.bg-custom-gray {
  background: #f3f3f3;
}

.bg-custom-purple {
  background: #582e91;
}

.bg-custom-red {
  background: #b50938;
}

.color-custom-purple {
  color: #582e91;
}

.color-custom-gray-100 {
  color: #959595;
}

.border-color-gray {
  border-color: #959595;
}

.border-custom {
  border-width: 0.5px;
}
</style>
