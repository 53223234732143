<template>
  <div class="min-h-screen flex flex-col pt-[100px]">
    <!-- <img class="w-[200px]" src="@/assets/images/airesearch_logo.webp" /> -->
    <div class="max-w-[1100px] mx-auto text-left">
      <div>
        <div class="flex justify-center items-center space-x-4 space-y-4 mt-4 px-[200px] flex-wrap">
          <img src="@/assets/images/airesearch_logo.webp" alt="airesearch" class="max-h-[100px]" />
          <img src="@/assets/images/logo/vistec_logo.png" alt="vistec" class="max-h-[80px]" />
          <img src="@/assets/images/logo/depa_logo.png" alt="depa" class="max-h-[100px]" />
          <img src="@/assets/images/logo/tham_logo.png" alt="tham" class="max-h-[90px]" />
        </div>
        <div class="text-2xl pt-[50px] text-center">
          ระบบสืบค้นคำพิพากษา คำสั่งคำร้องและคำวินิจฉัยศาลฎีกา
        </div>
        <div class="mt-4">
          ด้วยปัจจุบันเทคโนโลยีการประมวลผลภาษาธรรมชาติ (Natural Language Processing) โดยใช้ภาษาไทย
          ได้พัฒนาขึ้นและมีศักยภาพในการช่วยพัฒนาระบบสืบค้นข้อมูลขนาดใหญ่
          ประกอบกับความต้องการของนักกฎหมายในการใช้งานระบบสืบค้นข้อมูลทางกฎหมายในรูปแบบทางเลือกใหม่ ๆ
          เพิ่มมากขึ้น
        </div>
        <div class="mt-2">
          สถาบันวิจัยปัญญาประดิษฐ์ประเทศไทย (AIResearch)
          ซึ่งดำเนินการภายใต้สำนักวิชาวิทยาศาสตร์และเทคโนโลยีสารสนเทศ สถาบันวิทยสิริเมธี (School of
          Information Science and Technology, Vidyasirimedhi Institute of Science of Technology: IST
          VISTEC) และสำนักงานส่งเสริมเศรษฐกิจดิจิทัล (depa) ร่วมกับคณะนิติศาสตร์
          มหาวิทยาลัยธรรมศาสตร์ โดยการลงนามบันทึกข้อตกลงความร่วมมือด้านการวิจัย
          จึงได้จัดทำระบบสืบค้นข้อมูลทางกฎหมายในรูปแบบของแพลตฟอร์มขึ้น
        </div>
        <div class="mt-2">โดยฐานข้อมูลของระบบสืบค้นประกอบด้วยข้อมูลที่เผยแพร่สาธารณะ ได้แก่</div>
        <ol>
          <div>1. ประมวลกฎหมายจากสำนักงานคณะกรรมการกฤษฎีกา และ</div>
          <div>2. คำพิพากษา คำสั่งคำร้องและคำวินิจฉัยศาลฎีกา</div>
        </ol>
        <div class="instruction">
          <div class="text-2xl pt-[50px]">วิธีการใช้งาน</div>
          <ol>
            <li>
              1. พิมพ์คำค้นหาที่ต้องการลงในช่องค้นหา แล้วกด Enter หรือ กดที่เครื่องหมายแว่นขยาย
            </li>
            <li>
              2. ผลลัพธ์การค้นหาอ้างอิงจากข้อมูลจาก กองผู้ช่วยผู้พิพากษาศาลฎีกา และ กฏหมาย-มาตราจาก
              สำนักงานคณะกรรมการกฤษฎีกา สามารถเลือกดูเฉพาะประเภทได้จากแถบด้านซ้าย
              หากคำค้นหาตรงกับเนื้อหาในเอกสารคำจะขึ้นเป็นสีแดง แต่หากเป็นคำใกล้เคียง
              คำนั้นจะขึ้นเป็นสีเหลือง
              <div class="flex justify-center my-4">
                <img src="@/assets/images/howto1.png" alt="howto1" class="w-[500px]" />
              </div>
            </li>
            <li>
              3. <b class="text-red-600">ขอความร่วมมือ</b> คลิกที่ลูกศรขึ้น (↑)
              หากผลการค้นหาเป็นไปตามที่ต้องการ (มีความเกี่ยวข้องสูง) และคลิกที่ลูกศรลง (↓)
              หากผลการค้นหาไม่เป็นไปตามที่ต้องการ (มีความเกี่ยวข้องต่ำ)
              <div class="flex justify-center my-4">
                <img src="@/assets/images/howto2.png" alt="howto1" class="w-[500px]" />
              </div>
            </li>
            <li>
              4. สามารถเรียงลำดับผลการค้นหาได้ตามความเกี่ยวข้องและปีของคำพิพากษา
              <div class="flex justify-center my-4">
                <img src="@/assets/images/howto3.png" alt="howto1" class="w-[500px]" />
              </div>
            </li>
            <li>5. สามารถกรองคำพิพากษาโดยใช้มาตราได้</li>
            <li>
              6. กดที่ชื่อคำพิพากษา
              เพื่อดูรายละเอียดเพิ่มเติมซึ่งประกอบด้วยคำพิพากษาแบบย่อยาวจากศาลฎีกา
              <div class="flex justify-center my-4">
                <img src="@/assets/images/howto4.png" alt="howto1" class="w-[500px]" />
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  mounted() {
    setTimeout(() => {
      this.ga = window.ga;
      this.$gtm.trackEvent({
        event: 'clientID',
        client_id: this.ga.getAll()[0].get('clientId'),
      });
    }, 1000);
  },
  data() {
    return {
      ga: null,
    };
  },
};
</script>
<style scoped>
li {
  margin-top: 0.75rem !important;
}
</style>
