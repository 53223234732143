<template>
  <div class="fixed w-full bg-custom-gray p-3 no-print">
    <div class="w-[1100px] flex justify-between mx-auto">
      <a href="/"
        ><div>
          <img class="h-auto w-24" src="@/assets/images/airesearch_logo.webp" /></div
      ></a>

      <div class="flex justify-center items-end align-center">
        <div class="input-group flex-nowrap flex justify-center items-center">
          <input
            type="text"
            class="border-1 border-color-gray rounded-none py-1 px-3 outline-none w-80 text-xl"
            placeholder="ค้นหา"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            v-model="searchText"
            @keyup.enter="searchPage"
          />
          <button
            class="text-white bg-custom-purple px-6 h-[35px]"
            type="button"
            id="button-addon2"
            @click="searchPage"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div class="flex items-end">
        <router-link
          to="/search"
          class="
            color-custom-purple
            cursor-pointer
            no-underline
            hover:underline hover:color-custom-purple
          "
          >ค้นหาขั้นสูง</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchText: '',
    };
  },
  watch: {
    '$route.query'() {
      console.log(this.$route.query);
      this.searchText = this.$route.query.keyword;
    },
  },
  methods: {
    searchPage() {
      if (this.searchText.length) {
        this.$gtm.trackEvent({
          event: 'search',
          type: 'normal',
          search_keyword: this.searchText,
          search_query: `/result?keyword=${this.searchText}`,
        });
        this.$router.push({ path: `/result?keyword=${this.searchText}` });
        // window.open(`/result?keyword=${this.searchText}`, '_blank');
      }
    },
  },
};
</script>
<style scoped>
@media print {
  .no-print {
    display: none;
  }
}
</style>
