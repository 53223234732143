import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    results: [],
    total: 0,
    token: [],
    aggregations: [],
    kridsadika: {
      results: [],
      total: 0,
    },
    kridsadikaTotal: 0,
    legalType: [],
  },
  getters: {
    results: state => {
      return state.results;
    },
    resultByID: state => id => {
      return state.results.find(item => item['_source']['id'] == id);
    },
    token: state => {
      return state.token;
    },
  },
  mutations: {
    setResults(state, payload) {
      state.results = payload;
    },
    setTotal(state, payload) {
      state.total = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setAggregation(state, payload) {
      state.aggregations = payload;
    },
    setKridResults(state, payload) {
      state.kridsadika = payload;
    },
    setLawgalTypeAggregation(state, payload) {
      state.legalType = payload;
    },
    setKridsadikaTotal(state, payload) {
      state.kridsadikaTotal = payload;
    },
  },
  actions: {
    async fetchResult({ commit }, query) {
      let response = '';
      let kird_res = '';
      if (
        query.doc_type ||
        query.or_keyword ||
        query.not_keyword ||
        query.doc_no ||
        query.temp_law ||
        query.start_year ||
        query.end_year ||
        query.sort_type ||
        query.temp_type ||
        query.litigant ||
        query.judge
      ) {
        response = await axios.post('https://api.legal-search.airesearch.in.th/search-advance', {
          ...query,
        });
        kird_res = await axios.post(
          'https://api.legal-search.airesearch.in.th/search-advance-krisdika',
          {
            ...query,
          }
        );
      } else {
        response = await axios.post('https://api.legal-search.airesearch.in.th/search', {
          keyword: query.keyword,
          page_number: query.page_number ? query.page_number : 1,
        });
        if (
          query.source &&
          query.source != 'all' &&
          query.source != 'กองผู้ช่วยผู้พิพากษาศาลฎีกา' &&
          query.source != 'กฎหมาย-มาตรา'
        ) {
          kird_res = await axios.post(
            'https://api.legal-search.airesearch.in.th/search-advance-krisdika',
            {
              ...query,
              keyword: '',
              temp_type: query.source,
            }
          );
        } else {
          kird_res = await axios.post('https://api.legal-search.airesearch.in.th/search-krisdika', {
            keyword: query.keyword,
            page_number: query.page_number ? query.page_number : 1,
          });
        }
      }

      const results = response.data.hits;
      const total = response.data.total.value;
      const token = response.data.token;
      const aggregation = response.data.aggregation;
      commit('setResults', results);
      commit('setTotal', total);
      commit('setToken', token);
      commit('setLawgalTypeAggregation', aggregation.agg_laws.buckets);
      commit('setAggregation', aggregation.agg_articles.buckets);
      if (
        query.source &&
        query.source != 'all' &&
        query.source != 'กองผู้ช่วยผู้พิพากษาศาลฎีกา' &&
        query.source != 'กฎหมาย-มาตรา'
      ) {
        commit('setAggregation', aggregation.agg_articles.buckets);
      } else {
        const kridsadikaTotal = kird_res.data.total.value;
        commit('setKridsadikaTotal', kridsadikaTotal);
        commit('setAggregation', aggregation.agg_articles.buckets);
      }
      commit('setLawgalTypeAggregation', aggregation.agg_laws.buckets);
      commit('setKridResults', {
        results: kird_res.data.hits,
      });
    },
  },
});
